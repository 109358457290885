<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入标题" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.menu" clearable placeholder="请选择分类" size="small">
          <el-option
              v-for="item in menuList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="">-->
<!--        <el-select v-model="search.status" clearable placeholder="请选择上架状态" size="small">-->
<!--          <el-option-->
<!--              v-for="item in statusList"-->
<!--              :key="item.id"-->
<!--              :label="item.title"-->
<!--              :value="item.id">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <el-form-item label="">
        <el-select v-model="search.tjstatus" clearable placeholder="请选择推荐状态" size="small">
          <el-option
              v-for="item in tjList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.supplier" clearable placeholder="请选择供应商态" size="small">
          <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch(0)">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="title"
          label="标题">
      </el-table-column>
      <el-table-column
          prop="menu_ids_str"
          label="分类"
          align="center"
          width="200">
      </el-table-column>
      <el-table-column
          prop="imgs"
          label="图片"
          align="center"
          width="120">
        <template slot-scope="scope">
          <el-avatar shape="square" :size="60" :src="scope.row.img"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
          prop="price"
          label="价格"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="old_price"
          label="建议零售价"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="cost_price"
          label="成本价"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="supplier_name"
          label="供应商"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="stock"
          label="库存"
          align="center"
          width="100">
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="freight_free"-->
<!--          label="是否包邮"-->
<!--          align="center"-->
<!--          width="100">-->
<!--        <template slot-scope="scope">-->
<!--          {{ scope.row.freight_free==1?'包邮':'不包邮'}}-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
          prop="rec_time"
          label="推荐状态"
          align="center"
          width="100">
        <template slot-scope="scope">
          {{scope.row.rec_time>0?'推荐':'不推荐'}}
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="ordering"-->
<!--          label="排序"-->
<!--          align="center"-->
<!--          width="80">-->
<!--      </el-table-column>-->
      <el-table-column
          prop="create_time"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="chooseItem(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: '',
        tjstatus: '',
        supplier: '',
        menu: '',
        isExport: 0
      },
      tableData: [],
      statusList: [
        {id:1,title:'上架'},
        {id:2,title:'下架'},
      ],
      tjList: [
        {id:1,title:'推荐'},
        {id:2,title:'不推荐'},
      ],
      supplierList: [],
      menuList: []
    };
  },
  created() {
    this.initData()
  },
  mounted() {
  },
  computed: {},
  methods: {
    initData() {
      this.getList();
      this.getSupplierList()
      this.getMenuList()
    },
    getSupplierList() {
      this.$api.merchant.supplierIndex({page_size: 100}, res => {
        if (res.errcode == 0) {
          this.supplierList = res.data.data
        }
      })
    },
    getMenuList() {
      this.$api.goods.menuAll({}, res => {
        if (res.errcode == 0) {
          this.menuList = res.data
        }
      })
    },
    getList() {
      var that = this
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: 1,
        tjstatus: that.search.tjstatus,
        supplier: that.search.supplier,
        menu: that.search.menu,
        is_export: that.search.isExport
      }
      this.$api.goods.goodsIndex(param, function (res) {
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    chooseItem(item) {
      this.$emit('selectGoods',item)
    },
    handleSearch(isExport) {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
